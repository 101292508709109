<template>
  <b-container>
    <b-row class="text-center" style="padding-top: 10em">
      <b-col style="max-width: 800px; margin:0 auto">
        <b-link class="brand-logo">
          <b-img :src="appLogoDarkImage" alt="logo" class="d-block" style="margin: 0 auto" />
        </b-link>
        <h1 class="mt-5">Je bent er bijna 🎉</h1>
        <p>Je account is succesvol aangemaakt. We hebben een verificatie e-mail naar je e-mailadres toegestuurd. Klik op de link in deze e-mail om je account te activeren</p>
        <b-button variant="primary" @click="$router.push({ path: '/' })">Log in op Familiebank</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    // BSV
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      land: { "name": "Netherlands", "code": "NL" },
      password: '',
      password_confirm: '',
      status: '',
      infix: '',
      firstname: '',
      lastname: '',
      infixes: [{ "name": "van" }],

      // validation rules
      required,
      email
    }
  },
  setup() {
    const { appLogoDarkImage } = $themeConfig.app
    return {
      appLogoDarkImage
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.auth-wrapper {
  .auth-inner {
    max-width: 800px;
    margin: 0 auto;
  }
}
</style>
